﻿$base-theme: Default;
$skin-name: MBMC;
$accent: $brand-color;
$info: $blue-light;
$success: #1fa12e;
$warning: #f5a700;
$error: #f10031;
$text-color: #333333;
$bg-color: $white;
$base-text: #333333;
$base-bg: #f7f7f9;
$hovered-text: $brand-hovered-text-color;
$hovered-bg: #f7f7f9;
$selected-text: $white;
$selected-bg: $brand-selected-background-color;
$series-a: #428bca;
$series-b: #5bc0de;
$series-c: #5cb85c;
$series-d: #f2b661;
$series-e: #e67d4a;
$series-f: #da3b36;

$border-radius: 4px;