﻿@import "~@progress/kendo-theme-default/scss/variables";
@import "~@progress/kendo-theme-default/scss/mixins";

// Dependencies
@import "~@progress/kendo-theme-default/scss/common/all";
/*@import "~@progress/kendo-theme-default/scss/ripple";*/
/*@import "~@progress/kendo-theme-default/scss/popup";*/


// Data management
@import "~@progress/kendo-theme-default/scss/pager";
@import "~@progress/kendo-theme-default/scss/grid";
/*@import "~@progress/kendo-theme-default/scss/spreadsheet";*/
/*@import "~@progress/kendo-theme-default/scss/pivotgrid";*/
/*@import "~@progress/kendo-theme-default/scss/treelist";*/
/*@import "~@progress/kendo-theme-default/scss/scrollview";*/
/*@import "~@progress/kendo-theme-default/scss/listview";*/


// Editors
/*@import "~@progress/kendo-theme-default/scss/autocomplete";*/
@import "~@progress/kendo-theme-default/scss/colorpicker";
@import "~@progress/kendo-theme-default/scss/combobox";
/*@import "~@progress/kendo-theme-default/scss/datetime";*/
@import "~@progress/kendo-theme-default/scss/dropdownlist";
/*@import "~@progress/kendo-theme-default/scss/dropdowntree";*/
/*@import "~@progress/kendo-theme-default/scss/editor";*/
@import "~@progress/kendo-theme-default/scss/input";
@import "~@progress/kendo-theme-default/scss/multiselect";
@import "~@progress/kendo-theme-default/scss/numerictextbox";
@import "~@progress/kendo-theme-default/scss/slider";
@import "~@progress/kendo-theme-default/scss/upload";
@import "~@progress/kendo-theme-default/scss/validator";
@import "~@progress/kendo-theme-default/scss/switch";
/*@import "~@progress/kendo-theme-default/scss/maskedtextbox";*/
@import "~@progress/kendo-theme-default/scss/listbox";


// Dataviz
/*@import "~@progress/kendo-theme-default/scss/dataviz";*/
/*@import "~@progress/kendo-theme-default/scss/map";*/

// Barcodes
/*@import "~@progress/kendo-theme-default/scss/barcode";*/
/*@import "~@progress/kendo-theme-default/scss/qrcode";*/

// Scheduling
@import "~@progress/kendo-theme-default/scss/calendar";
/*@import "~@progress/kendo-theme-default/scss/gantt";*/
/*@import "~@progress/kendo-theme-default/scss/scheduler";*/


// Media
/*@import "~@progress/kendo-theme-default/scss/mediaplayer";*/


// Layout
@import "~@progress/kendo-theme-default/scss/dialog";
/*@import "~@progress/kendo-theme-default/scss/notification";*/
/*@import "~@progress/kendo-theme-default/scss/responsivepanel";*/
/*@import "~@progress/kendo-theme-default/scss/splitter";*/
@import "~@progress/kendo-theme-default/scss/tooltip";
/*@import "~@progress/kendo-theme-default/scss/window";*/


// Navigation
@import "~@progress/kendo-theme-default/scss/button";
@import "~@progress/kendo-theme-default/scss/menu";
/*@import "~@progress/kendo-theme-default/scss/panelbar";*/
@import "~@progress/kendo-theme-default/scss/tabstrip";
@import "~@progress/kendo-theme-default/scss/toolbar";
@import "~@progress/kendo-theme-default/scss/treeview";


// Interactivity & UX
@import "~@progress/kendo-theme-default/scss/progressbar";
/*@import "~@progress/kendo-theme-default/scss/scroller";*/


// Styling & Content
@import "~@progress/kendo-theme-default/scss/styling";
/*@import "~@progress/kendo-theme-default/scss/badge";*/
@import "~@progress/kendo-theme-default/scss/card";


// Chat
/*@import "~@progress/kendo-theme-default/scss/chat";*/
.k-grid a.brand-color {
    color: $brand-color;
}
