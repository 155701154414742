﻿.k-grid.transparent tr.k-alt {
    background-color: rgba(0, 0, 0, 0.04);
}

.k-grid.transparent tr {  
    background-color: rgba(0, 0, 0, 0.04);
}

.k-tooltip {
    background: white !important;
    background-color: white !important;
    border: 1px solid #ddd !important;
}
.k-callout {
    color: white !important;
}